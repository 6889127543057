import { Grid, Typography } from '@mui/material';
import OpportunityFileUpload from 'components/common/OpportunityFiles/OpportunityFileUpload';
import { FormikProps } from 'formik';
import { IFileMetadata } from 'global/interfaces/file';
import { IApplyOpportunity } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { saveOpportunityFile } from 'services/storageService';
import {
  handleAddFile,
  handleDeleteFile,
  handleFileNameChange,
  handleMoveLeftFile,
  handleMoveRightFile,
  onUploadStatusChange,
} from 'utils/file';

interface ApplyAttachFilesProps {
  form: FormikProps<IApplyOpportunity>;
  oppId: string;
  helperText: string;
}

const maxFileSize = 8 * 1024 * 1024;

export default function ApplyAttachfiles({ form, oppId, helperText }: ApplyAttachFilesProps) {
  const [files, setFiles] = useState<IFileMetadata[]>(() => form.values.files);

  useEffect(() => {
    if (files != form.initialValues.files) {
      form.setFieldValue('files', files);
    }
  }, [files]);

  return (
    <>
      <Grid>
        <Typography variant={'subtitle1'} fontWeight={700} mb={0.5} fontSize={'18px'}>
          Attachments
        </Typography>
        <Typography variant="body2">Optional: Upload any additional files requested in the brief.</Typography>
      </Grid>
      <OpportunityFileUpload
        onAddFile={file => handleAddFile(file, setFiles)}
        onDelete={path => handleDeleteFile(path, setFiles)}
        onMoveLeft={path => handleMoveLeftFile(path, setFiles)}
        onMoveRight={path => handleMoveRightFile(path, setFiles)}
        onNameChange={(path, fileName) => handleFileNameChange(path, fileName, setFiles)}
        onUploadStatusChange={status => onUploadStatusChange(form, status)}
        files={form.values.files}
        isSellingServices={false}
        uploadFunc={(file, controller) => saveOpportunityFile(file, controller, oppId)}
        mt={1}
        maxFileSize={maxFileSize}
        helperText={helperText}
        acceptedFiles="*"
        hideHeading
        compactMode
      />
    </>
  );
}
