export interface UserSubscription {
  subscriptionCancelAtPeriodEnd: boolean;
  cardLast4: string;
  subscriptionRenewedOn: string;
  subscriptionCurrentPeriodEnd: string;
  cancelAttempt?: number;
  cancellationCouponSet: boolean;
  discountApplied: boolean;
}

//NB:: Maps to cancellation reason in Stripe
export const enum DowngradeReason {
  low_quality,
  unused,
  switched_service,
  too_expensive,
  customer_service,
  other,
}

export interface IDowngradeSubscription {
  reason: DowngradeReason | null;
  comment?: string;
}
