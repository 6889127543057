import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledSelectField, StyledTextField } from '../OppFilters';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Dispatch, useEffect } from 'react';
import { ApplicationStatusType, IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';

const OPPSEARCHPARAM = 'oppsearch';
const APPSTATUSPARAM = 'app-status';

interface IMyOppFilterProps {
  setLoading: Dispatch<boolean>;
  opps: IOpportunity[];
  setFilteredOpps: Dispatch<IOpportunity[]>;
}

export default function MyOppFilters({ opps, setLoading, setFilteredOpps }: IMyOppFilterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();

  const form = useFormik<{ search: string; appStatus: string }>({
    initialValues: {
      search: searchParams.get(OPPSEARCHPARAM) ?? '',
      appStatus: searchParams.get(APPSTATUSPARAM) ?? ApplicationStatusType.All,
    },
    onSubmit: values => {
      setLoading(true);

      const params = new URLSearchParams(searchParams);
      if (values.search) {
        params.set(OPPSEARCHPARAM, values.search);
      } else {
        params.delete(OPPSEARCHPARAM);
      }
      if (values.appStatus) {
        params.set(APPSTATUSPARAM, values.appStatus);
      } else {
        params.delete(APPSTATUSPARAM);
      }

      setTimeout(() => {
        setSearchParams(params);
        setLoading(false);
      }, 300);
    },
  });

  useEffect(() => {
    if (!searchParams.has(APPSTATUSPARAM) && !searchParams.has(OPPSEARCHPARAM)) {
      setFilteredOpps(opps);
      return;
    }

    let filt: IOpportunity[] = opps;
    if (searchParams.has(APPSTATUSPARAM)) {
      const statusSearch = searchParams.get(APPSTATUSPARAM);
      filt = filterApplicationStatus(statusSearch ?? '', opps);
    }
    if (searchParams.has(OPPSEARCHPARAM)) {
      const oppsearch = searchParams.get(OPPSEARCHPARAM) ?? '';
      filt = filt.filter(
        f =>
          f.description.toLowerCase().includes(oppsearch.toLowerCase()) ||
          f.title.toLowerCase().includes(oppsearch.toLowerCase()) ||
          (f.location ? f.location.toLowerCase().includes(oppsearch.toLowerCase()) : false),
      );
    }
    setFilteredOpps(filt);
  }, [searchParams, opps]);

  return (
    <Stack
      sx={{ mb: !isMobile ? 5 : 0, mt: !isMobile ? 5 : 0 }}
      spacing={2}
      direction={'column'}
      component="form"
      onSubmit={form.handleSubmit}
    >
      <Typography variant="subtitle1" mb={0.25}>
        Filter proposals
      </Typography>
      <StyledSelectField
        label="Status"
        value={form.values.appStatus}
        name="appStatus"
        size={'small'}
        items={mapApplicationStatus()}
        onChange={form.handleChange}
        fullWidth
      />
      <StyledTextField
        name="search"
        label="Keywords"
        value={form.values.search}
        onChange={form.handleChange}
        autoComplete="off"
        placeholder="Enter Keywords"
        size={'small'}
        fullWidth
        InputProps={{
          autoComplete: 'off',
        }}
      />
      <RoundButton variant="contained" type="submit">
        Update Results
      </RoundButton>
    </Stack>
  );
}

function mapApplicationStatus(): SelectItem[] {
  return Object.keys(ApplicationStatusType).map((k: string) => ({
    id: k,
    label: ApplicationStatusType[k as keyof typeof ApplicationStatusType],
  }));
}

function filterApplicationStatus(status: string, opps: IOpportunity[]): IOpportunity[] {
  if (status === ApplicationStatusType.All || status === '') {
    return opps;
  }
  if (opps.some(o => o.applicants && o.applicants?.length > 1)) {
    throw new Error('Applicant list cannot have more than one');
  }

  if (ApplicationStatusType[status as keyof typeof ApplicationStatusType] === ApplicationStatusType.NotTrackable) {
    return opps.filter(o => !o.isAssisted && o.type === OpportunityType.External);
  }
  if (ApplicationStatusType[status as keyof typeof ApplicationStatusType] === ApplicationStatusType.Delivered) {
    return opps.filter(
      o =>
        (o.isAssisted || o.type === OpportunityType.Shoutt) &&
        o.applicants &&
        !o.applicants[0].firstOpenedOn &&
        !o.applicants[0].firstClickedOn,
    );
  }
  if (ApplicationStatusType[status as keyof typeof ApplicationStatusType] === ApplicationStatusType.ViewedOnly) {
    return opps.filter(o => o.applicants && o.applicants[0].firstOpenedOn && !o.applicants[0].firstClickedOn);
  }
  if (ApplicationStatusType[status as keyof typeof ApplicationStatusType] === ApplicationStatusType.Clicked) {
    return opps.filter(o => o.applicants && o.applicants[0].firstClickedOn);
  }

  throw new Error('Application type not handled');
}
