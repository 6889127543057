import { Box, CircularProgress, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import {
  ApplyInstructionsType,
  ApplyStageType,
  IOppApplyExternalReponse,
  IOpportunity,
} from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';

import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { applied, applyToExternal, notInterested } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { pushToDataLayer } from 'utils/tagHelper';
import { appliedOpportunity } from 'global/constants';
import { Link } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

interface IApplyExternalOpportunity {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateApplyStageOnClose: (newStage: ApplyStageType) => void;
}

export default function ApplyExternalOpportunity(props: IApplyExternalOpportunity): JSX.Element {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [externalApplyInfo, setExternalApplyInfo] = useState<IOppApplyExternalReponse | null>(null);

  useEffect(() => {
    if (!props.open || !props.opportunity) {
      return;
    }
    setLoading(true);
    applyToExternal(props.opportunity.id)
      .then(res => {
        setExternalApplyInfo(res);
        setLoading(false);
      })
      .catch(showError);
    pushToDataLayer(appliedOpportunity, {
      transaction_id: props.opportunity.id,
    });
  }, [props.opportunity, props.open]);

  const applyConfirmed = () => {
    if (props.opportunity?.id) {
      applied(props.opportunity?.id);
    }
    props.updateApplyStageOnClose(ApplyStageType.Applied);
  };

  const notInterestedConfirmed = () => {
    if (props.opportunity?.id) {
      notInterested(props.opportunity?.id);
    }
    props.updateApplyStageOnClose(ApplyStageType.NotInterested);
  };

  return (
    <>
      {props.opportunity != null && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              {props.opportunity.title}
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            {externalApplyInfo?.type == ApplyInstructionsType.Text && !loading && (
              <>
                <Typography variant="body1">
                  For this external lead, please follow the instructions below. Don&apos;t forget to craft a concise and
                  compelling response that addresses the brief, rather than a generic intro.
                </Typography>
                <Typography variant="body1">{externalApplyInfo?.applyInstructions}</Typography>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Did you send a proposal?
                </Typography>
              </>
            )}
            {externalApplyInfo?.type == ApplyInstructionsType.Url && !loading && (
              <>
                <Typography variant="body1">
                  For this external lead please continue your proposal via the site where we sourced the lead. Craft a
                  concise and compelling response that addresses the brief, rather than a generic intro.
                </Typography>

                <Link
                  to={externalApplyInfo?.applyInstructions ?? ''}
                  target="_blank"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Complete Proposal Now <OpenInNewOutlinedIcon sx={{ width: '18px', height: '18px', ml: 0.75 }} />
                </Link>
                <Typography variant="subtitle1" fontWeight={600} mb={1}>
                  Did you send a proposal?
                </Typography>
              </>
            )}
            {loading && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <RoundButton variant="outlined" onClick={notInterestedConfirmed} fullWidth>
                  No, not interested
                </RoundButton>
              </Grid>

              <Grid item xs={6}>
                <RoundButton variant="contained" onClick={applyConfirmed} fullWidth>
                  Yes, I&apos;ve pitched
                </RoundButton>
              </Grid>
            </Grid>
          </StyledDialogContent>
        </>
      )}
    </>
  );
}
