import { Box, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  JustifiedBox,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAssistedChip,
  StyledChip,
  StyledShouttChip,
} from '../OpportunityAccordion';
import {
  ApplyInstructionsType,
  IApplicant,
  IOpportunity,
  OpportunityLocationType,
  OpportunityType,
} from 'global/interfaces/opportunity';
import React from 'react';
import { ApplicantAttachments } from 'views/opportunities/ApplicantAttachments';
import OpenButton from './openButton';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { format } from 'date-fns';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import GpsOffOutlinedIcon from '@mui/icons-material/GpsOffOutlined';

interface IMyOpportunityProps {
  opportunity: IOpportunity;
}

export default function MyOpportunityAccordion({ opportunity }: IMyOpportunityProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExternalUrl =
    opportunity.type === OpportunityType.External &&
    !opportunity.isAssisted &&
    opportunity.applyInstructionsType === ApplyInstructionsType.Url;
  const isExternalEmail =
    opportunity.type === OpportunityType.External &&
    !opportunity.isAssisted &&
    opportunity.applyInstructionsType === ApplyInstructionsType.Text;

  return (
    <StyledAccordion elevation={10} disableGutters>
      <StyledAccordionSummary>
        <JustifiedBox>
          <Stack direction="column" gap={isMobile ? 0.75 : 1} width={isMobile ? '100%' : undefined}>
            <Stack direction="row" justifyContent={isMobile ? 'space-between' : 'flex-start'}>
              <Typography variant="subtitle1" fontSize={{ xs: '16px', md: '18px' }} component={'div'}>
                <span style={{ marginRight: '8px' }}>{opportunity.title}</span>
                {opportunity.isFree && !isMobile && (
                  <StyledChip
                    label={<Typography variant={'caption'}>Free to Apply</Typography>}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                )}

                {opportunity.type == OpportunityType.Shoutt && !isMobile && (
                  <StyledShouttChip
                    label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                    size="small"
                  />
                )}

                {opportunity.type == OpportunityType.External && !isMobile && (
                  <>
                    {opportunity.isAssisted ? (
                      <StyledAssistedChip
                        label={<Typography variant={'caption'}># Shoutt Assisted</Typography>}
                        size="small"
                      />
                    ) : (
                      <>
                        {opportunity.applyInstructionsType === ApplyInstructionsType.Text ? (
                          <StyledChip
                            label={<Typography variant={'caption'}>Apply via Email</Typography>}
                            size="small"
                          />
                        ) : (
                          <StyledChip label={<Typography variant={'caption'}>External</Typography>} size="small" />
                        )}
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Stack ml={0.5}>
                <OpenButton url={`/leads/${opportunity.id}`} />
              </Stack>
            </Stack>

            {opportunity.type == OpportunityType.Shoutt && isMobile && (
              <Box>
                <StyledShouttChip
                  label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                  size="small"
                />
                {opportunity.isFree && (
                  <StyledChip
                    label={<Typography variant={'caption'}>Free to Apply</Typography>}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
            )}

            {opportunity.type == OpportunityType.External && isMobile && (
              <Box>
                {opportunity.isAssisted ? (
                  <StyledAssistedChip
                    label={<Typography variant={'caption'}># Shoutt Assisted</Typography>}
                    size="small"
                  />
                ) : (
                  <>
                    {opportunity.applyInstructionsType === ApplyInstructionsType.Text ? (
                      <StyledChip label={<Typography variant={'caption'}>Apply via Email</Typography>} size="small" />
                    ) : (
                      <StyledChip label={<Typography variant={'caption'}>External</Typography>} size="small" />
                    )}
                  </>
                )}
                {opportunity.isFree && (
                  <StyledChip
                    label={<Typography variant={'caption'}>Free to Apply</Typography>}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Box>
            )}

            <Typography
              variant="body2"
              color={theme.palette.grey[500]}
              component={Box}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {opportunity.categories.map((category: string, index: number) => (
                <React.Fragment key={index}>
                  <span style={{ marginRight: '10px' }}>{category}</span>
                  <span style={{ marginRight: '10px' }}>{'•'}</span>
                </React.Fragment>
              ))}
              {opportunity.locationType == OpportunityLocationType.Remote ? (
                <span style={{ marginRight: '10px' }}>Remote (Worldwide)</span>
              ) : (
                <span style={{ marginRight: '10px' }}>{opportunity.location}</span>
              )}
              {opportunity.budget && (
                <>
                  <span style={{ marginRight: '10px' }}>{'•'}</span>
                  <span style={{ marginRight: '10px' }}>{opportunity.budget}</span>
                </>
              )}
            </Typography>

            {isMobile && (
              <JustifiedBox paddingRight={'4px'}>
                <Stack direction={'row'} gap={0.5} minWidth={'100px'} mt={0.5}>
                  <CalendarMonthOutlinedIcon sx={{ stroke: theme.palette.grey[300] }} />
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    {opportunity.applicants &&
                      opportunity.applicants[0].appliedDate &&
                      format(new Date(opportunity.applicants[0].appliedDate), 'yyyy-MM-dd')}
                  </Typography>
                </Stack>
                {opportunity.applicants && (
                  <OpportunityIcon applicant={opportunity.applicants[0]} isMobile={isMobile} opp={opportunity} />
                )}
              </JustifiedBox>
            )}
          </Stack>
          {!isMobile && (
            <Stack direction={'row'} gap={0.5} ml={1}>
              <CalendarMonthOutlinedIcon sx={{ stroke: theme.palette.grey[300] }} />
              <Typography variant="body2" color={theme.palette.grey[500]} noWrap>
                {opportunity.applicants &&
                  opportunity.applicants[0].appliedDate &&
                  format(new Date(opportunity.applicants[0].appliedDate), 'yyyy-MM-dd')}
              </Typography>
              {opportunity.applicants && (
                <OpportunityIcon applicant={opportunity.applicants[0]} isMobile={isMobile} opp={opportunity} />
              )}
            </Stack>
          )}
        </JustifiedBox>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <Stack direction="column" spacing={0} width="100%" gap={3}>
          {opportunity.applicants?.map(applicant => (
            <React.Fragment key={applicant.userId}>
              {applicant.title && (
                <Typography variant={isMobile ? 'body2' : 'body1'} whiteSpace="break-spaces">
                  <span style={{ fontWeight: 600 }}>Subject: </span>
                  {applicant.title}
                </Typography>
              )}
              {applicant.message && (
                <>
                  <Typography variant={isMobile ? 'body2' : 'body1'} sx={{ fontWeight: 600 }} whiteSpace="break-spaces">
                    Cover note:
                  </Typography>
                  <Typography
                    variant={isMobile ? 'body2' : 'body1'}
                    whiteSpace="break-spaces"
                    dangerouslySetInnerHTML={{ __html: applicant.message }}
                  />
                </>
              )}
              {applicant.files && <ApplicantAttachments files={applicant.files} />}
            </React.Fragment>
          ))}
          {isExternalUrl && (
            <Typography variant={isMobile ? 'body2' : 'body1'} color="grey.900" whiteSpace="break-spaces">
              You completed your application on the external site below:
            </Typography>
          )}

          {isExternalEmail && (
            <Typography variant={isMobile ? 'body2' : 'body1'} color="grey.900" whiteSpace="break-spaces">
              You completed your application by following the below instructions:
            </Typography>
          )}

          {isExternalUrl && (
            <a href={opportunity.applyInstructions} target="_blank" rel="noreferrer">
              External link
            </a>
          )}
          {isExternalEmail && (
            <Typography variant={isMobile ? 'body2' : 'body1'} color="grey.900" whiteSpace="break-spaces">
              {opportunity.applyInstructions}
            </Typography>
          )}
        </Stack>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

function OpportunityIcon({
  applicant,
  isMobile,
  opp,
}: {
  applicant: IApplicant;
  isMobile: boolean;
  opp: IOpportunity;
}) {
  const theme = useTheme();
  let iconColor: string | undefined = undefined;
  let tooltipText: string;
  const notTracked = opp.type === OpportunityType.External && !opp.isAssisted;

  if (applicant.firstClickedOn) {
    iconColor = theme.palette.success.main;
    tooltipText = 'Your application has been viewed and there has been at least 1 click';
  } else if (applicant.firstOpenedOn) {
    iconColor = theme.palette.primary.main;
    tooltipText = 'Your application has been viewed';
  } else if (!notTracked) {
    tooltipText = 'Your application has been delivered but it has not been viewed yet';
  } else {
    tooltipText = 'As this is an external application, we cannot track if your application has been viewed or clicked';
  }

  return (
    <>
      <Tooltip
        disableFocusListener
        title={tooltipText}
        arrow
        enterTouchDelay={300}
        placement={isMobile ? 'left' : 'bottom'}
      >
        {!notTracked ? (
          <DoneAllOutlinedIcon
            sx={{
              marginLeft: '20px',
              color: iconColor,
            }}
          />
        ) : (
          <GpsOffOutlinedIcon
            sx={{
              marginLeft: '20px',
            }}
          />
        )}
      </Tooltip>
    </>
  );
}
