import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DetailContainer } from 'components/terms/Containers';
import { useTitle } from 'utils/router';

import HeroSection from 'components/info/Hero';
import personIm from 'assets/images/lps/personimage5.webp';
import SaleemIm from 'assets/images/user/Saleem.png';
import JonathanIm from 'assets/images/user/Jonathan.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const StyledIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.grey[700]};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function About(): JSX.Element {
  useTitle('About Us');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <HeroSection
        title={'About us'}
        subtext="Shoutt is a growth platform built for freelancers who want more freedom, better leads, and effortless payments—without the noise."
        minHeight={isMobileScreen ? '260px' : '470px'}
        personImage={personIm}
      />
      <Box bgcolor="#F8F7F4">
        <Container maxWidth="lg">
          <DetailContainer>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Shoutt is a growth platform built for freelancers who want more freedom, better leads, and effortless
              payments—without the noise.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              We’re a small team of engineers and marketers from the UK who got tired of outdated platforms,
              race-to-the-bottom pricing, and unnecessary friction. So we built something better—a{' '}
              <b>more ethical approach</b> to helping freelancers to build their businesses.
            </Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={1} mb={1}>
              No Commissions. No Lock-In. No Nonsense.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Most platforms take a cut of your earnings or force you to work within their ecosystem. Not Shoutt. You
              keep 100% of what you earn, work with clients however you choose, and never worry about suspension
              threats.
            </Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={1} mb={1}>
              Quality Over Quantity
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              Freelancers shouldn’t have to sift through lowball offers, and businesses shouldn’t struggle to find
              skilled professionals. That’s why we vet both sides.
              <ul>
                <li>
                  <b>Talent:</b> Every profile is checked for experience, skills, and professionalism. No hobbyists,
                  just top-tier talent.
                </li>
                <li>
                  <b>Leads:</b> No junk gigs, no vague briefs. We curate 1,000+ high-quality freelance leads per month
                  from 30+ sources, all screened for legitimacy and fair budgets. We’re also working hard to bring you
                  more exclusive Shoutt opportunities.
                </li>
              </ul>
            </Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={1} mb={1}>
              An Ethical, Transparent Approach
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              At Shoutt, we believe in doing things the right way. We give you the tools to grow your business without
              being part of an exploitative system. No hidden fees, no commissions, and no unethical practices.
            </Typography>

            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
              We’re here to empower freelancers by offering a platform that respects your independence and rewards your
              skills fairly. We’re committed to transparency and ensuring that both freelancers and clients can build
              lasting, ethical working relationships.
            </Typography>
            <Typography variant={isMobileScreen ? 'body2' : 'body1'}>This is freelancing, redefined.</Typography>
            <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={1} mb={isMobileScreen ? 0 : 1}>
              Our Founders
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6} lg={5}>
                <Stack direction={'row'} spacing={2} mt={isMobileScreen ? 2 : undefined} alignItems={'center'}>
                  <Avatar
                    sx={{
                      width: isMobileScreen ? '90px' : '96px',
                      height: isMobileScreen ? '90px' : '96px',

                      mt: isMobileScreen ? 1.5 : undefined,
                      borderRadius: '12px',
                    }}
                    src={SaleemIm}
                    variant="square"
                  ></Avatar>
                  <Stack direction={'column'} pt={1}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color={'#101828'}
                      fontSize={isMobileScreen ? '20px' : undefined}
                    >
                      Saleem Yaqub
                    </Typography>
                    <Typography
                      variant={isMobileScreen ? 'body2' : 'body1'}
                      fontWeight={400}
                      color={'#475467'}
                      mt={0.5}
                    >
                      Co-Founder & CEO
                    </Typography>
                    <Link to="https://www.linkedin.com/in/saleemyaqub/" target="_blank">
                      <StyledIconButton sx={{ padding: 0 }}>
                        <LinkedInIcon
                          sx={{
                            height: isMobileScreen ? '24px' : '28px',
                            width: isMobileScreen ? '24px' : '28px',
                            mt: 0.5,
                            cursor: 'pointer',
                          }}
                        ></LinkedInIcon>
                      </StyledIconButton>
                    </Link>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Stack direction={'row'} spacing={2} mt={isMobileScreen ? 2 : undefined} alignItems={'center'}>
                  <Avatar
                    sx={{
                      width: isMobileScreen ? '90px' : '96px',
                      height: isMobileScreen ? '90px' : '96px',
                      mt: isMobileScreen ? 1.5 : undefined,
                      borderRadius: '12px',
                    }}
                    src={JonathanIm}
                    variant="square"
                  ></Avatar>
                  <Stack direction={'column'} pt={1}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color={'#101828'}
                      fontSize={isMobileScreen ? '20px' : undefined}
                    >
                      Jonathan Eadie
                    </Typography>
                    <Typography
                      variant={isMobileScreen ? 'body2' : 'body1'}
                      fontWeight={400}
                      color={'#475467'}
                      mt={0.5}
                    >
                      Co-Founder & CTO
                    </Typography>
                    <Link to="https://www.linkedin.com/in/jonathan-eadie/" target="_blank">
                      <StyledIconButton sx={{ padding: 0 }}>
                        <LinkedInIcon
                          sx={{
                            height: isMobileScreen ? '24px' : '28px',
                            width: isMobileScreen ? '24px' : '28px',
                            mt: 0.5,
                            cursor: 'pointer',
                          }}
                        ></LinkedInIcon>
                      </StyledIconButton>
                    </Link>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </DetailContainer>
        </Container>
      </Box>
    </>
  );
}
