import { Container, Grid, styled, useMediaQuery, useTheme } from '@mui/material';

import { IOpportunity } from 'global/interfaces/opportunity';
import { Dispatch } from 'react';

import { useLocalization } from 'global/hooks/useLocalization';
import NewOpportunitySteps from './newOpportunitySteps';
import NewOpportunityStepCarousel from './newOpportunityStepCarousel';
import personIm from 'assets/images/lps/personimage3.webp';

import HeroSection, { IReview, IUsp } from 'components/info/Hero';

import { ClockIcon } from 'components/icon/ClockIcon';

import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import { BriefIcon } from 'components/icon/BriefIcon';

import OpportunityForm from './OpportunityForm';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

export default function OpportunityPage({
  setSubmitted,
  isExternal,
  opportunity,
}: {
  setSubmitted: Dispatch<boolean>;
  isExternal: boolean;
  opportunity?: IOpportunity;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(29);

  const review: IReview = {
    name: 'Michael A',
    roleTitle: 'Business Owner',
    reviewText: '"This is the best way to find freelance talent!"',
  };

  const usps: IUsp[] = [
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: '100% Free Service',
      text: 'There are no hidden fees, obligations or commissions to worry about on either side.',
    },
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Protect Your Email Inbox',
      text: 'Share your opportunity link and we’ll pass all candidates through our vetting process.',
    },

    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Vetted Talent Only',
      text: 'We screen all candidates to ensure your time is not wasted with low quality pitches.',
    },
  ];

  return (
    <>
      <HeroSection
        title={isExternal ? 'Post an External Lead' : 'Avoid being inundated with low quality freelance applicants'}
        review={review}
        usps={usps}
        personImage={personIm}
      />
      {!isExternal && isMobile && <NewOpportunityStepCarousel currencySymbol={localCurrencySymbol} />}
      <StyledContainer maxWidth={isExternal ? 'md' : 'lg'}>
        <Grid container>
          {!isExternal && !isMobile && (
            <Grid item xs={0} md={4}>
              <NewOpportunitySteps currencySymbol={localCurrencySymbol} />
            </Grid>
          )}

          <Grid container item xs={12} md={isExternal ? 12 : 8}>
            <OpportunityForm setSubmitted={setSubmitted} isExternal={isExternal} opportunity={opportunity} />
          </Grid>
        </Grid>
      </StyledContainer>
    </>
  );
}
