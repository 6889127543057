import { Box, CircularProgress, Container, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { IOpportunity } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getOpportunityForConversion } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { useLocalization } from 'global/hooks/useLocalization';
import NewOpportunityStepCarousel from 'components/opportunities/newOpportunityStepCarousel';
import HeroSection, { IUsp } from 'components/info/Hero';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ClockIcon } from '@mui/x-date-pickers';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import NewOpportunitySteps from 'components/opportunities/newOpportunitySteps';
import OpportunityForm from 'components/opportunities/OpportunityForm';
import { NewOpportunityConfirmation } from 'components/opportunities/newOpportunityConfirmation';
import OppNoAccess from './OppNoAccess';
import OppConverted from './OppConverted';
import OppNotFound from './OppNotFound';
import personIm from 'assets/images/lps/personimage7.webp';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

export function ConvertOpportunity() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [opp, setOpp] = useState<IOpportunity>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(29);
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const [converted, setConverted] = useState(false);
  const [noAcess, setNoAccess] = useState(false);

  useTitle('Convert Opportunity');

  useEffect(() => {
    if (!id) return;

    getOpportunityForConversion(id, searchParams.get('accessToken'))
      .then(res => setOpp(res))
      .catch(e => {
        if (e.status === 400) {
          setConverted(true);
          return;
        }
        if (e.status === 401) {
          setNoAccess(true);
          return;
        }
        if (e.status === 404) {
          return;
        }
        showError(e);
      })
      .finally(() => setLoading(false));
  }, []);

  if (!loading && converted) {
    return <OppConverted />;
  }

  if (!loading && noAcess) {
    return <OppNoAccess />;
  }

  if (!loading && !opp) {
    return <OppNotFound />;
  }

  const usps: IUsp[] = [
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: '100% Free Service',
      text: 'There are no hidden fees, obligations or commissions to worry about on either side.',
    },
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Protect Your Email Inbox',
      text: 'Share your opportunity link and we’ll pass all candidates through our vetting process.',
    },

    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Vetted Talent Only',
      text: 'We screen all candidates to ensure your time is not wasted with low quality pitches.',
    },
  ];

  return (
    <Box bgcolor={!submitted ? '#F8F7F4' : '#FFFFFF'}>
      {!submitted ? (
        <>
          {opp ? (
            <>
              <HeroSection
                title={'Turn your brief into a Shoutt Exclusive for FREE'}
                usps={usps}
                subtext="Find the perfect freelancer for your project. Turning your brief into a Shoutt Exclusive in just a few clicks and get more pre-screened applicants."
                personImage={personIm}
              />
              {isMobile && <NewOpportunityStepCarousel currencySymbol={localCurrencySymbol} />}
              <StyledContainer maxWidth={'lg'}>
                <Grid container>
                  {!isMobile && (
                    <Grid item xs={0} md={4}>
                      <NewOpportunitySteps currencySymbol={localCurrencySymbol} />
                    </Grid>
                  )}

                  <Grid container item xs={12} md={8}>
                    <OpportunityForm
                      setSubmitted={setSubmitted}
                      isExternal={false}
                      opportunity={opp}
                      accessToken={searchParams.get('accessToken') ?? ''}
                    />
                  </Grid>
                </Grid>
              </StyledContainer>
            </>
          ) : (
            <StyledContainer>
              <Box sx={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
                <CircularProgress />
              </Box>
            </StyledContainer>
          )}
        </>
      ) : (
        <NewOpportunityConfirmation />
      )}
    </Box>
  );
}
