import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';

interface PriceFeature {
  text: string;
}

const sellerFreePriceFeatures: PriceFeature[] = [
  { text: 'Submit proposals for up to 100 selected free leads every month' },
];

const sellerPremPriceFeatures: PriceFeature[] = [
  { text: 'Access 1,000+ high-quality freelance leads per month, sourced from 30+ trusted sites' },
  {
    text: 'Unlimited proposals—no restrictions on reaching out to leads',
  },
  { text: 'Create profile and get listed on vetted marketplace so clients can find and contact you directly' },
  { text: 'Daily/weekly email alerts with fresh, curated freelance leads directly to your inbox' },
];

const sellerPlusFeatures: PriceFeature[] = [
  { text: 'Send invoices and accept payments via card, bank transfer, and Apple/Google Pay' },
  { text: 'Stay protected with optional two-way escrow for secure payments' },
  {
    text: 'Share your Shoutt profile, get paid, and collect verified reviews to build credibility',
  },
  {
    text: 'No commissions. No hidden fees',
  },
];

const buyerFreePriceFeatures: PriceFeature[] = [
  { text: 'Post briefs easily, only vetted talent can respond, saving you time' },
  { text: 'Browse talent profiles and send messages to our vetted freelance talent' },
];

const buyerPremPriceFeatures: PriceFeature[] = [
  { text: 'Pay freelancers via Shoutt with secure escrow protection to protect your funds' },
  { text: 'Create contracts with freelancers to help manage deliverables and scope of work' },
];

interface PricingTableProps {
  isSeller: boolean;
  currencySybmol: string;
  premPrice: number;
  freeBgColor?: string;
  freeCTA?: React.ReactNode;
  premCTA?: React.ReactNode;
}

const StyledPriceBox = styled(Box)(() => ({
  padding: '40px 32px 40px 32px',
  borderRadius: '24px',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

interface StyledFreePriceBoxProps {
  freeBgColor?: string;
}

const StyledFreePriceBox = styled(StyledPriceBox, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'freeBgColor',
})<StyledFreePriceBoxProps>(() => ({
  background: 'linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%)',
  border: '1px solid #D9D8D6',
}));

const StyledPremiumPriceBox = styled(StyledPriceBox)(() => ({
  background: 'linear-gradient(180deg, #F1F4FE 0%, #EAEEFA 100%)',

  border: '1px solid #2D5CE6',
}));

const PriceFeatureTypography = styled(Typography)(() => ({
  lineHeight: '140%',
  fontSize: '16px',

  color: '#101828',
}));

const EverythingFreeTypography = styled(Typography)(() => ({
  padding: '4px 8px 4px 8px',
  gap: '10px',
  borderRadius: '4px',
  background: '#FFFFFF',
  border: '1px solid #4D7AFF',
  boxShadow: '0px 1px 3px 0px #00000026',
}));

const PriceFreeFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#101828',
}));

const PricePremFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#101828',
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function PricingTable({ currencySybmol, premPrice, freeBgColor, freeCTA, premCTA }: PricingTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container spacing={{ xs: 0, md: 4 }} rowSpacing={4}>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
        <StyledFreePriceBox freeBgColor={freeBgColor}>
          <Box mb={isMobile ? 1 : 3}>
            <Typography variant="h6" fontWeight={500} fontSize={'36px'} color={'#101828'}>
              Free
            </Typography>
            <Typography
              variant="body1"
              color={'#5E6779'}
              mt={1.5}
              component={'div'}
              fontWeight={700}
              fontSize={{ xs: '16px', lg: '18px' }}
            >
              {currencySybmol}0 per month
              {!isMobile && (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
            </Typography>
            <Typography
              mt={{ xs: 1.8, md: 2.6 }}
              variant="body2"
              textTransform={'uppercase'}
              color={'#101828'}
              fontWeight={700}
            >
              For Freelancers
            </Typography>
            <Divider sx={{ mt: 1.5 }} color="#9EA5B3"></Divider>
            <List sx={{ mt: isMobile ? 1.5 : 2.5 }}>
              {sellerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PriceFreeFeatureTypography variant="body1">{feature.text}</PriceFreeFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Box mb={isMobile ? 1 : 3.25}>
              <Typography
                mt={{ xs: 2, md: 3 }}
                variant="body2"
                textTransform={'uppercase'}
                color={'#101828'}
                fontWeight={700}
              >
                For Businesses
              </Typography>
              <Divider sx={{ mt: 1.5 }} color="#9EA5B3"></Divider>

              <List sx={{ mt: isMobile ? 1.5 : 2.5 }}>
                {buyerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                  <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                    <StyledListItemIcon>
                      <StyledDoneIcon />
                    </StyledListItemIcon>
                    <ListItemText>
                      <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {!isMobile && (
                <Typography variant="body2" color={'#475467'}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              )}
            </Box>
            <Box width={'100%'}> {freeCTA}</Box>
          </Box>
        </StyledFreePriceBox>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-start' }}>
        <StyledPremiumPriceBox>
          <Box mb={isMobile ? 1 : 3}>
            <Typography variant="h6" fontWeight={500} fontSize={'36px'} color={'#101828'}>
              Premium
            </Typography>

            <Typography
              variant="body1"
              color={'#5E6779'}
              mt={1.5}
              component={'div'}
              fontWeight={700}
              fontSize={{ xs: '16px', lg: '18px' }}
            >
              {currencySybmol}
              {premPrice} per month
            </Typography>
            <Typography variant="body1" color={'#5E6779'} component={'div'}>
              Cancel anytime in settings
            </Typography>
            <Typography
              mt={{ xs: 2, md: 3 }}
              variant="body2"
              textTransform={'uppercase'}
              color={'#101828'}
              fontWeight={700}
            >
              For Freelancers
            </Typography>
            <Divider sx={{ mt: 1.5 }} color="#9EA5B3"></Divider>
            <Box mt={2.5}>
              <EverythingFreeTypography variant="caption" color={'#2D5CE6'} fontWeight={500}>
                Everything in FREE +
              </EverythingFreeTypography>
            </Box>
            <Typography variant="body1" color={'#101828'} mt={2.5} textTransform={'uppercase'} fontWeight={700}>
              GROW YOUR BUSINESS
            </Typography>
            <List>
              {sellerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Typography variant="body1" color={'#101828'} mt={2.5} textTransform={'uppercase'} fontWeight={700}>
              BUILD CREDIBILITY & GET PAID
            </Typography>
            <List>
              {sellerPlusFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>

            <Typography
              mt={{ xs: 2, md: 3 }}
              variant="body2"
              textTransform={'uppercase'}
              color={'#101828'}
              fontWeight={700}
            >
              For Businesses
            </Typography>
            <Divider sx={{ mt: 1.5 }} color="#9EA5B3"></Divider>
            <Box mt={2.5}>
              <EverythingFreeTypography variant="caption" color={'#2D5CE6'} fontWeight={500}>
                Everything in FREE +
              </EverythingFreeTypography>
            </Box>
            <List>
              {buyerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {premCTA}
        </StyledPremiumPriceBox>
      </Grid>
    </Grid>
  );
}
