import { IDowngradeSubscription, UserSubscription } from 'global/interfaces/userSubscription';
import { authorizedGet, authorizedPost, authorizedPut } from './baseApiService';

const resource = 'Subscription';

export async function createProductCheckout(returnUrl?: string): Promise<string> {
  return await authorizedPost<string>(`${resource}?${returnUrl ? `returnUrl=${returnUrl}` : ''}`, {});
}

export async function cancelSubscription(cancelDetails: IDowngradeSubscription): Promise<void> {
  return await authorizedPost<void>(`${resource}/set-cancellation`, cancelDetails);
}

export async function stopCancellation(): Promise<void> {
  return await authorizedPost<void>(`${resource}/stop-cancellation`, {});
}

export async function editCardDetails(): Promise<string> {
  return await authorizedPost<string>(`${resource}/edit-card`, {});
}

export async function getCurrentUserSubscription(): Promise<UserSubscription> {
  return await authorizedGet<UserSubscription>(`${resource}`);
}

export async function setCancellationCoupon(): Promise<boolean> {
  return await authorizedPut<boolean>(`${resource}/apply-cancel-coupon`, {});
}
