import { FaqOption } from 'global/interfaces/faq';

export const billingFaqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'Where do you get the leads from?',
    answer:
      'We source 1,000+ high-quality freelance leads every month from 30+ trusted platforms, updating them throughout the day so you always get fresh, relevant opportunities—never older than 7 days.\n\nWe’re also working on bringing you more Shoutt exclusive leads posted directly on our platform.',
  },
  {
    id: '02',
    question: 'Why pay when I can find leads for free?',
    answer:
      'Sure, you could—but it takes hours of research. Our team spends 40+ hours per week sourcing and curating the best leads, so you can skip the legwork and focus on landing clients.\n\nAt less than the price of a pizza, Shoutt Premium saves you time and pays for itself quickly. One of our earliest users has landed two long term contracts, giving her a ROI of over 10,000%!',
  },
  {
    id: '03',
    question: 'What makes Shoutt different?',
    answer: (
      <ul style={{ marginTop: '0px' }}>
        <li>No race to the bottom. No commissions. Less noise.</li>
        <li>
          100% freelancer-friendly—use Shoutt to find leads, but work however you want. No ecosystem lock-in or
          suspension threats.
        </li>
        <li>Vetted leads—we filter out low-quality listings, so you don’t waste time on junk.</li>
        <li>Shoutt helps freelancers grow sustainably—on their own terms.</li>
      </ul>
    ),
  },
  {
    id: '04',
    question: 'What else do you offer apart from leads?',
    answer: (
      <>
        <p style={{ marginTop: '0px' }}>
          Shoutt is more than just leads—it’s a growth toolkit for serious freelancers.
        </p>
        <ul style={{ marginTop: '0px' }}>
          <li>Invoicing & payments – Send invoices, accept payments, and get paid fast.</li>
          <li>Payment protection – Optional escrow ensures clients pay upfront, reducing risk.</li>
          <li>
            Profile & reviews – Every invoice helps you build credibility by collecting verified reviews. This works
            with off-platform clients too with no sign up requirement for your clients.{' '}
          </li>
        </ul>
        <p style={{ marginTop: '0px' }}>
          These tools help you win more work, get paid smoothly, and build long-term success.
        </p>
      </>
    ),
  },
  {
    id: '05',
    question: 'How does payment protection work?',
    answer: (
      <>
        <p style={{ marginTop: '0px' }}>
          If you want extra security, enable Shoutt’s escrow protection when invoicing a client. This can be used for on
          and off platform clients (coming soon).
        </p>
        <ul style={{ marginTop: '0px' }}>
          <li>Clients pay upfront to activate the project.</li>
          <li>Funds are held securely while the work gets done.</li>
          <li>Once work is approved, payment is released.</li>
          <li>This ensures no awkward payment chases or unpaid invoices. </li>
        </ul>
        <p style={{ marginTop: '0px' }}>
          These tools help you win more work, get paid smoothly, and build long-term success.
        </p>
      </>
    ),
  },
  {
    id: '06',
    question: 'How can I cancel?',
    answer:
      'Simply go to Account Menu > My Subscriptions and downgrade to the free plan. You’ll keep full access until your next billing date—no contracts, no hassle.',
  },
  {
    id: '07',
    question: 'Where is Shoutt based?',
    answer:
      'Shoutt is a UK-based startup with a remote team across the UK, US, and Europe. We support 40+ countries in GBP, EUR, and USD and are backed by Innovate UK (a government-backed accelerator) and Microsoft for Startups.',
  },
];
