import { Box, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import CategoryMultiSelect from 'components/common/Select/CategoryMultiSelect';
import FormSelect from 'components/common/Select/FormSelect';
import { AuthContext } from 'contexts/AuthContext';
import { useFormik } from 'formik';
import { frequencyItems, NewsLetterFrequency } from 'global/enums/newsletterFrequency';
import { INewsletterPreference } from 'global/interfaces/newsletter';
import { Dispatch, useContext, useState } from 'react';
import { subscribeToNewsletter } from 'services/newsletterService';
import { showError } from 'utils/errorHandler';
import { array, object, string } from 'yup';

export default function OpportunitySubscribeForm({ setCompleted }: { setCompleted: Dispatch<boolean> }) {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<INewsletterPreference>({
    initialValues: {
      email: authContext.user?.email ?? '',
      categories: [],
      frequency: NewsLetterFrequency.Daily,
      opportunitiesEnabled: true,
    },
    onSubmit: values => {
      setLoading(true);
      subscribeToNewsletter(values)
        .catch(showError)
        .finally(() => {
          setLoading(false);
          setCompleted(true);
        });
    },
    validationSchema: object({
      email: string().required('Email is required').max(255, 'Email must be no more than 255 characters'),
      categories: array().required().min(1, 'You must select at least 1 category'),
      frequency: string().required(),
    }),
  });

  return (
    <>
      <Typography variant="subtitle1" color={theme.palette.common.black} fontWeight={600} width={{ lg: '450px' }}>
        Get free email alerts for new leads
      </Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} gap={1} justifyContent="right" sx={{ width: { xs: '100%' } }}>
        <TextField
          name="email"
          value={form.values.email}
          placeholder="Email"
          onChange={form.handleChange}
          fullWidth
          size="small"
          variant="outlined"
          error={form.touched.email === true && Boolean(form.errors.email)}
          disabled={authContext.user !== null}
          sx={{ maxWidth: { md: '300px' } }}
          helperText={form.touched.email !== undefined ? form.errors.email : ''}
        />

        <Box sx={{ width: { md: '250px' } }}>
          <CategoryMultiSelect
            name="categories"
            value={form.values.categories}
            onChange={form.handleChange}
            placeholder="Categories"
            variant={'outlined'}
            multiple
            size="small"
            compact={!isMobile}
            error={form.touched.categories !== undefined && Boolean(form.errors.categories)}
            helpertext={form.touched.categories !== undefined ? form.errors.categories : ''}
          />
        </Box>

        <Box sx={{ width: { md: '100px' } }}>
          <FormSelect
            name="frequency"
            value={form.values.frequency}
            placeholder="Frequency"
            onChange={form.handleChange}
            fullWidth
            size="small"
            variant="outlined"
            error={form.touched.frequency === true && Boolean(form.errors.frequency)}
            items={frequencyItems}
            helpertext={form.touched.frequency !== undefined ? form.errors.frequency : ''}
          />
        </Box>

        <RoundButton
          variant="contained"
          loading={loading}
          onClick={form.submitForm}
          sx={{
            width: { xs: '100%', md: 'auto' },
            backgroundColor: '#081218',
            '&:hover': {
              backgroundColor: '#2a3238',
            },
          }}
        >
          Submit
        </RoundButton>
      </Stack>
    </>
  );
}
