import { DowngradeReason, IDowngradeSubscription } from 'global/interfaces/userSubscription';
import { mixed, object, SchemaOf, string } from 'yup';

export const downgradeSubscriptionValidation: SchemaOf<IDowngradeSubscription> = object({
  reason: mixed<DowngradeReason>().required('You must select a reason'),
  comment: string()
    .notRequired()
    .when('reason', {
      is: DowngradeReason.other,
      then: string().required('You must enter a comment'),
    })
    .max(300, 'Reson must be 300 or less characters'),
});
