import { MainSectionBox } from './MainSectionBox';
import { Badge, Box, Grow, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';
import { showError } from 'utils/errorHandler';
import { useIsComponentMounted } from 'global/hooks/useIsComponentMounted';
import { IOpportunity } from 'global/interfaces/opportunity';
import { PersonIcon } from 'components/icon/PersonIcon';
import { getMyOpportunities } from 'services/opportunityService';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ApplicantsBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-weight: 700;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledTitleTypography = styled(Typography)(() => ({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default function ItemMyPostedOpportunities() {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const isComponentMounted = useIsComponentMounted();

  useEffect(() => {
    loadOpportunities();
  }, []);

  const loadOpportunities = () => {
    if (isComponentMounted.current) {
      setLoading(true);
      setOpportunities([]);
    }

    getMyOpportunities(30)
      .then((res: IOpportunity[]) => {
        if (res.length > 6) res = res.slice(0, 6);
        if (isComponentMounted.current) {
          setOpportunities(res);
          setLoading(false);
        }
      })
      .catch(showError);
  };
  return (
    <>
      {!loading && opportunities.length > 0 ? (
        <MainSectionBox>
          <JustifiedBox>
            <Stack direction="row" spacing={1.5}>
              <Typography variant="subtitle1" fontWeight={600}>
                My Posts
              </Typography>
            </Stack>
          </JustifiedBox>

          <Grow in={true} timeout={200}>
            <Box mb={1} mt={2.5}>
              {opportunities.map((opportunity, index: number) => (
                <Stack key={index} direction="column" mt={1}>
                  <JustifiedBox alignItems={'center'}>
                    <StyledTitleTypography
                      variant="subtitle2"
                      color={!opportunity.isLive ? theme.palette.grey[400] : undefined}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => window.open(`/leads/${opportunity.id}`, '_blank')}
                    >
                      {opportunity.title} {!opportunity.isLive && ' (Expired)'}{' '}
                    </StyledTitleTypography>

                    <IconButton onClick={() => window.open(`/applicants/${opportunity.id}`, '_blank')}>
                      <ApplicantsBadge badgeContent={opportunity.applicantCount}>
                        <PersonIcon style={{ color: theme.palette.primary.main }} />
                      </ApplicantsBadge>
                    </IconButton>
                  </JustifiedBox>
                </Stack>
              ))}
            </Box>
          </Grow>
        </MainSectionBox>
      ) : (
        <></>
      )}
    </>
  );
}
