import { useTitle } from 'utils/router';

import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import HeroSection, { BannerStack, IUsp } from 'components/info/Hero';
import DoneIcon from '@mui/icons-material/Done';

import contracttilt from 'assets/images/lps/createcontract_tilt.svg';
import stripelogo from 'assets/images/lps/stripelogo.png';
import innovatelogo from 'assets/images/lps/innovatelogo.png';
import microsoftlogo from 'assets/images/lps/microsoftlogo.png';
import personIm from 'assets/images/lps/personimage4.webp';
import invoiceIm from 'assets/images/lps/img-bg-invoice-cta.jpg';

import Talent from './buyer/Talent';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ClockIcon } from 'components/icon/ClockIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';

const StyledLogoImage = styled('img')(({ theme }) => ({
  height: '60px',
  [theme.breakpoints.down('lg')]: {
    height: '50px',
  },
  [theme.breakpoints.down('md')]: {
    height: '40px',
  },
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#F17522',
  marginRight: '8px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

const StyledInvoiceBox = styled(Box)(() => ({
  display: 'flex',
  padding: '48px 32px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

const StyledImage = styled('img')(() => ({
  position: 'absolute',
  top: '-20px',
  left: '40px',
  height: '115%',
}));

const FeatureTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '140%',
  fontSize: '16px',
  color: theme.palette.common.white,
  fontWeight: 500,
}));

export default function Info() {
  useTitle('Shoutt | Freelancing made better');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const usps: IUsp[] = [
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Find Quality Leads',
      text: 'Access 1,000+ fresh, high-quality freelance leads every month—no junk, just real leads.',
    },
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Get Discovered',
      text: 'List your profile on our vetted network and attract inbound clients looking for your skills.',
    },
    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Build Credibility',
      text: 'Send invoices, get paid, and collect verified reviews to strengthen your reputation.',
    },
  ];

  return (
    <>
      <Box sx={{ background: '#F8F7F4' }}>
        <HeroSection
          title={'Find Clients. Get Paid. Stay Independent.'}
          subtext="Shoutt is a toolkit for freelancers who want to grow their business—on their terms."
          usps={usps}
          personImage={personIm}
        />
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={{ xs: 3, md: 5, lg: 7 }} alignItems={'center'} mt={1}>
            <Box
              width={'100%'}
              marginTop={{ xs: '30px', md: '50px' }}
              sx={{
                backgroundImage: `url(${invoiceIm})`,
                backgroundSize: isMobile ? 'auto 100%' : 'cover',
                backgroundPosition: 'right',
                borderRadius: '18px',
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={5} position={'relative'}>
                  <StyledImage src={contracttilt} />
                </Grid>
                <Grid item xs={12} md={7}>
                  <StyledInvoiceBox>
                    <Typography
                      variant="h4"
                      fontSize={{ xs: '24px', md: '30px', lg: '36px' }}
                      fontWeight={500}
                      maxWidth={'600px'}
                      color={theme.palette.common.white}
                    >
                      Invoices, payment protection and contracts - all rolled into one{' '}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: '16px', lg: '18px' }}
                      mt={{ xs: '20px', md: '36px' }}
                      mb={{ xs: '20px', md: '36px' }}
                      color={theme.palette.common.white}
                    >
                      Our nifty solution for safely handling transactions means both parties can focus on getting great
                      work done, no stress!
                    </Typography>
                    <Grid container columnSpacing={4}>
                      <Grid item xs={12} md={6}>
                        <List disablePadding>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">
                                Issue and pay invoices with a range of methods
                              </FeatureTypography>
                            </ListItemText>
                          </ListItem>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">Secure two way escrow protection</FeatureTypography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <List disablePadding>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">
                                Contracts with clear cut terms to avoid headaches
                              </FeatureTypography>
                            </ListItemText>
                          </ListItem>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">100% commission free</FeatureTypography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </StyledInvoiceBox>
                </Grid>
              </Grid>
            </Box>
            <Box
              width={'100%'}
              alignContent={'center'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
              mt={4}
            >
              <Typography
                variant="h3"
                fontSize={{ xs: '24px', md: '28px', lg: '36px' }}
                color={theme.palette.common.black}
                maxWidth={'900px'}
                fontWeight={500}
                textAlign={'center'}
                lineHeight={{ xs: '32px', md: '34px', lg: '44px' }}
                mb={{ xs: 1, md: 5 }}
              >
                Check out some of our awesome freelance talent
              </Typography>
              <Talent />
            </Box>
            <Stack direction={'column'} alignItems="center" spacing={{ xs: 4, md: 6 }} mb={6} mt={-1}>
              <Typography
                variant="h3"
                fontSize={{ xs: '24px', md: '28px', lg: '36px' }}
                color={theme.palette.common.black}
                maxWidth={'900px'}
                fontWeight={500}
                textAlign={'center'}
                lineHeight={{ xs: '32px', md: '34px', lg: '44px' }}
              >
                We’re proud to partner with
              </Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 8 }} alignItems={'center'}>
                <StyledLogoImage src={microsoftlogo} />
                <StyledLogoImage src={innovatelogo} />
                <StyledLogoImage src={stripelogo} />
              </Stack>
            </Stack>
          </BannerStack>
        </Container>
      </Box>
    </>
  );
}
