import {
  Avatar,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledDialogActions, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import CloseIcon from '@mui/icons-material/Close';
import { showError } from 'utils/errorHandler';
import { pushToDataLayer } from 'utils/tagHelper';
import { beginCheckoutEvent } from 'global/constants';
import DoneIcon from '@mui/icons-material/Done';
import userIm from 'assets/images/user/Imogen.png';
import { PriceFeatureTypography, StyledDialogContent } from './ApplyDialog';

interface PriceFeature {
  text: string;
}

const growFeatures: PriceFeature[] = [
  { text: 'Access 1,000+ high-quality freelance leads per month, sourced from 30+ trusted sites' },
  {
    text: 'Unlimited proposals—no restrictions on reaching out to leads',
  },
  { text: 'Create profile and get listed on vetted marketplace so clients can find and contact you directly' },
];

const freeFeatures: PriceFeature[] = [
  { text: 'Send invoices and accept payments via card, bank transfer, and Apple/Google Pay' },
  { text: 'Stay protected with optional two-way escrow for secure payments' },
  { text: 'Share your Shoutt profile, get paid, and collect verified reviews to build credibility' },
];

const TopTypography = styled(PriceFeatureTypography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.common.black,
}));

const StyledTopBox = styled(Box)(({ theme }) => ({
  padding: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '16px',
  backgroundColor: '#F2F4F7',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function GoPremiumInfo({ onClose }: { onClose: () => void }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const createSubscriptionSession = () => {
    setLoading(true);
    pushToDataLayer(beginCheckoutEvent, {
      transaction_id: authContext.user?.id,
    });
    createProductCheckout(`${window.location.href}`)
      .then(res => {
        window.location.href = res;
        setLoading(false);
      })
      .catch(showError);
  };

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h5" color={theme.palette.grey[900]}>
          Go Premium
        </Typography>
        <IconButton onClick={onClose} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledTopBox>
          {!isMobileScreen && (
            <>
              <TopTypography variant="body1">
                {' '}
                “I’ve already earned {localCurrencySymbol}10,000 and landed two long-term clients”
              </TopTypography>

              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Avatar
                  sx={{
                    width: '36px',
                    height: '36px',
                    border: '1px solid #A28CF3',
                  }}
                  src={userIm}
                ></Avatar>

                <TopTypography variant="body1">
                  <span
                    style={{ textDecoration: 'underline', color: '#2D5CE6' }}
                    onClick={() => {
                      window.open('https://talent.shoutt.co/Share/HcQSO8jtVEGh7TRsW3nq_g', '_blank');
                    }}
                  >
                    Imogen H
                  </span>
                </TopTypography>
              </Stack>
            </>
          )}
          {isMobileScreen && (
            <>
              <TopTypography variant="body1" alignItems={'center'}>
                “I’ve already earned {localCurrencySymbol}10,000 and landed two long-term clients” {' - '}
                <span
                  style={{ textDecoration: 'underline', color: '#2D5CE6' }}
                  onClick={() => {
                    window.open('https://talent.shoutt.co/Share/HcQSO8jtVEGh7TRsW3nq_g', '_blank');
                  }}
                >
                  Imogen H
                </span>
              </TopTypography>
            </>
          )}
        </StyledTopBox>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: '28px',
                borderRadius: '12px',
                background: 'linear-gradient(180deg, #F1F4FE 0%, #EAEEFA 100%)',

                paddingBottom: '20px',
              }}
            >
              <Stack direction={'row'} spacing={1}>
                <Typography variant="h4" fontSize={'36px'} fontWeight={600} color={'#101828'}>
                  {localCurrencySymbol}
                  {price}
                </Typography>
                <Stack direction={'column'}>
                  <Typography variant="body2" color={'#5E6779'}>
                    per month
                  </Typography>
                  <Typography variant="body2" color={'#5E6779'}>
                    cancel anytime
                  </Typography>
                </Stack>
              </Stack>
              <Divider
                sx={{
                  width: '80%',
                  backgroundColor: '#9EA5B3',
                  mt: '18px',
                  mb: '24px',
                }}
              ></Divider>
              <Typography variant="body2" color={'#101828'} fontWeight={700}>
                GROW YOUR BUSINESS
              </Typography>
              <List>
                {growFeatures.map((feature: PriceFeature, p: number) => (
                  <ListItem key={p} alignItems="flex-start" disableGutters dense>
                    <StyledListItemIcon>
                      <StyledDoneIcon />
                    </StyledListItemIcon>
                    <ListItemText>
                      <PriceFeatureTypography variant="body1">{feature.text}</PriceFeatureTypography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              <Typography variant="body2" color={'#101828'} fontWeight={700} mt={1.5}>
                BUILD CREDIBILITY & GET PAID
              </Typography>
              <List>
                {freeFeatures.map((feature: PriceFeature, p: number) => (
                  <ListItem key={p} alignItems="flex-start" disableGutters dense>
                    <StyledListItemIcon>
                      <StyledDoneIcon />
                    </StyledListItemIcon>
                    <ListItemText>
                      <PriceFeatureTypography variant="body1">{feature.text}</PriceFeatureTypography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={onClose}>
          {!isMobileScreen ? <>No, I&apos;m not ready to find more work</> : <>No, I&apos;m not ready</>}
        </RoundButton>
        <RoundButton
          fullWidth={isMobileScreen}
          loading={loading}
          variant="contained"
          onClick={() => createSubscriptionSession()}
        >
          Go Premium Now
        </RoundButton>
      </StyledDialogActions>
    </>
  );
}

export function PremiumDialog({ onClose, open }: { onClose: () => void; open: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={open} maxWidth="md" fullScreen={isMobile}>
      <GoPremiumInfo onClose={onClose} />
    </Dialog>
  );
}
