import { NewOpportunityConfirmation } from 'components/opportunities/newOpportunityConfirmation';
import OpportunityPage from 'components/opportunities/OpportunityPage';
import { useState } from 'react';
import { useTitle } from 'utils/router';

export default function NewExternalJob() {
  useTitle('Post an External Lead');
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      {!submitted ? <OpportunityPage setSubmitted={setSubmitted} isExternal={true} /> : <NewOpportunityConfirmation />}
    </>
  );
}
