import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function OpenButton({ url }: { url: string }) {
  return (
    <Link to={url} target="_blank">
      <IconButton sx={{ marginLeft: '3px', padding: '3px' }}>
        <OpenInNewIcon />
      </IconButton>
    </Link>
  );
}
