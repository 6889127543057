import { Alert, styled, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { VettingStatus } from 'global/enums/vettingStatus';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoIcon } from 'components/icon/InfoIcon';
import { WarningIcon } from 'components/icon/WarningIcon';
import { dismissVetting } from 'services/userService';
import { PremiumDialog } from 'components/opportunities/apply/GoPremiumInfo';

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: '12px',

  [theme.breakpoints.down('md')]: {
    borderRadius: '0px',
  },
}));

interface StyledInfoAlertProps {
  darker: boolean;
}

const StyledInfoAlert = styled(StyledAlert, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'darker',
})<StyledInfoAlertProps>(({ theme, darker }) => ({
  backgroundColor: darker ? theme.palette.grey[100] : theme.palette.grey[50],
}));

interface IVettingAlertProps {
  sx?: SxProps<Theme>;
  darkerInfo?: boolean;
}

export default function VettingAlert(props: IVettingAlertProps): JSX.Element {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dismissed, setDismissed] = useState<boolean>(authContext.vettingMessageDismissed ?? false);
  const [showPremium, setShowPremium] = useState(false);

  const updateDismissed = () => {
    setDismissed(true);
    dismissVetting().then(() => authContext.setVettingMessageDismissed());
  };

  useEffect(() => {
    if (authContext.vettingMessageDismissed && !dismissed) {
      setDismissed(true);
    }
  }, [authContext.vettingMessageDismissed]);

  return (
    <>
      <PremiumDialog open={showPremium} onClose={() => setShowPremium(false)} />
      {authContext.user?.vettingStatus == VettingStatus.NotSubmitted && !dismissed && (
        <StyledInfoAlert
          severity="info"
          icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
          onClose={updateDismissed}
          darker={props.darkerInfo ?? false}
          sx={props.sx}
        >
          Looking to sell services? You are not currently listed on our marketplace. Click{' '}
          <span
            style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
            onClick={() =>
              authContext.user?.subscriptionActive
                ? navigate(`/users/${authContext.user?.id}/edit?apply=true`)
                : setShowPremium(true)
            }
          >
            here
          </span>{' '}
          {authContext.user?.subscriptionActive
            ? 'to apply now and be discovered by new clients.'
            : 'to upgrade to premium and be discovered by new clients.'}
        </StyledInfoAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.Approved && authContext.user?.isSellingServices && (
        <StyledAlert severity="success" sx={props.sx}>
          You profile has been approved and you are listed on our marketplace. You will receive connection / quote
          requests directly from buyers and no bidding is necessary.
        </StyledAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.Approved &&
        !authContext.user?.isSellingServices &&
        !dismissed && (
          <StyledInfoAlert
            severity="info"
            icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
            sx={props.sx}
            onClose={updateDismissed}
            darker={props.darkerInfo ?? false}
          >
            You profile has been approved but you are not listed on our marketplace as you have selling services
            switched off in your profile.
          </StyledInfoAlert>
        )}
      {authContext.user?.vettingStatus == VettingStatus.InProgress && (
        <StyledInfoAlert
          severity="info"
          icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
          sx={props.sx}
          darker={props.darkerInfo ?? false}
        >
          Your profile is currently being reviewed by our moderators, please allow 2 business days. Feel free to carry
          on editing in the meantime.
        </StyledInfoAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.UpdatesRequired && (
        <StyledAlert severity="warning" icon={<WarningIcon />} sx={props.sx}>
          Please update your profile according to the following feedback and then re-submit: {isMobile && <br />}
          <i style={{ paddingRight: '4px' }}>
            {'"'}
            {authContext.user?.feedback}
            {'"'}
          </i>
        </StyledAlert>
      )}
      {authContext.user?.vettingStatus == VettingStatus.Rejected && !dismissed && (
        <StyledInfoAlert
          severity="info"
          icon={<InfoIcon sx={{ color: theme.palette.grey[400] }} />}
          sx={props.sx}
          onClose={updateDismissed}
          darker={props.darkerInfo ?? false}
        >
          Your profile has been declined so you will not be listed on our marketplace. Please feel free to try again in
          3 months time. You can still use the other features.
        </StyledInfoAlert>
      )}
    </>
  );
}
