import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Button, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { drawerWidth, HeaderLogo, MainAppBar, StyledHeaderBadge, StyledHeaderIconButton } from './headerStyles';
import logo from '../../assets/images/logo/Logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HideOnScroll } from './mainAppDrawerUtils';
import UserSearch from 'components/common/Search/UserSearch';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getConnectionsCount } from 'services/connectionService';
import { IConnectionCount } from 'global/interfaces/connection';
import { showError } from 'utils/errorHandler';
import { useCustomEventListener } from 'react-custom-events';
import { ConnectionRestarted, MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { getNumberUnread } from 'services/chatMessageService';
import { IChatUnread } from 'global/interfaces/chatMessage';
import { UserAppDrawer } from './userAppDrawer';
import { RoundButton } from 'components/common/Button/RoundButton';
import { helpUrl } from 'global/constants';
import { redirectToLogin } from 'utils/router';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0.5),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  minHeight: '55px',
}));

export default function MainAppDrawer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const [numberOfConnections, setNumberOfConnections] = useState<number>(0);
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (route: string) => () => {
    setOpen(false);
    navigate(route);
  };

  const loadNumberUnread = (): void => {
    if (authContext.user) {
      getNumberUnread()
        .then((res: IChatUnread) => {
          setNumberOfUnreadMessages(res.numberUnread);
        })
        .catch(showError);
    }
  };

  const loadNumberConnections = (): void => {
    if (authContext.user) {
      getConnectionsCount()
        .then((res: IConnectionCount) => {
          setNumberOfConnections(res.count);
        })
        .catch(showError);
    }
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewMessage],
    () => {
      loadNumberUnread();
      if (numberOfConnections < 1) {
        loadNumberConnections();
      }
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ReadMessages],
    () => {
      loadNumberUnread();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UpdateThread],
    () => {
      loadNumberUnread();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.SubscriptionEnded],
    () => {
      authContext.refresh();
    },
    [authContext],
  );

  const loadAll = () => {
    if (!authContext.loading) {
      loadNumberUnread();
      loadNumberConnections();
    }
  };

  useEffect(() => {
    loadAll();
  }, [authContext.user, authContext.loading]);

  useCustomEventListener(
    ConnectionRestarted,
    () => {
      loadAll();
    },
    [authContext],
  );

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HideOnScroll>
          <MainAppBar position="fixed" open={open}>
            <Container maxWidth="lg">
              <Toolbar disableGutters variant="dense">
                <Stack alignItems="center" direction="row">
                  {isMobile && (
                    <IconButton color="default" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                      <MenuIcon htmlColor={theme.palette.common.white} />
                    </IconButton>
                  )}
                  <Stack component={Link} to="/">
                    <HeaderLogo alt="Shoutt" src={logo} />
                  </Stack>
                  {!isMobile && (
                    <>
                      <Stack direction="row">
                        <UserSearch />
                      </Stack>
                      <RoundButton
                        aria-label="explore talent"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => navigate('/talent')}
                        sx={{
                          paddingX: { xs: '12px', lg: '16px' },
                          marginLeft: { xs: '22px', lg: '42px' },
                          color: theme.palette.common.white,
                          letterSpacing: '0.02em',

                          fontWeight: 400,
                        }}
                        variant="text"
                      >
                        Talent
                      </RoundButton>
                      <RoundButton
                        aria-label="explore leads"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => navigate('/leads')}
                        sx={{
                          paddingX: { xs: '12px', lg: '16px' },
                          marginLeft: '10px',
                          color: theme.palette.common.white,
                          letterSpacing: '0.02em',

                          fontWeight: 400,
                        }}
                        variant="text"
                      >
                        Leads
                      </RoundButton>
                      <RoundButton
                        aria-label="explore talent"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => navigate('/newbrief')}
                        sx={{
                          paddingX: { xs: '12px', lg: '16px' },
                          marginLeft: '10px',
                          color: theme.palette.common.white,
                          letterSpacing: '0.02em',

                          fontWeight: 400,
                        }}
                        variant="text"
                      >
                        Post Brief
                      </RoundButton>
                      <RoundButton
                        aria-label="explore talent"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => navigate(authContext.user ? '/subscriptions' : '/pricing')}
                        sx={{
                          paddingX: { xs: '12px', lg: '16px' },
                          marginLeft: '10px',
                          color: theme.palette.common.white,
                          letterSpacing: '0.02em',

                          fontWeight: 400,
                        }}
                        variant="text"
                      >
                        {isLarge ? ' Pricing & FAQs' : 'Pricing'}
                      </RoundButton>
                    </>
                  )}
                </Stack>
                <Stack direction="row" gap={isMobile ? 1 : 0}>
                  {authContext.user && (
                    <>
                      {(numberOfConnections > 0 || numberOfUnreadMessages > 0) && (
                        <StyledHeaderIconButton
                          size="medium"
                          color="secondary"
                          onClick={handleButtonClick('/workroom')}
                        >
                          <StyledHeaderBadge color="primary" badgeContent={numberOfUnreadMessages}>
                            <MailOutlineIcon htmlColor={theme.palette.common.white} />
                          </StyledHeaderBadge>
                        </StyledHeaderIconButton>
                      )}
                    </>
                  )}
                  {!authContext.user && (
                    <Stack alignItems="center" direction="row" spacing={{ xs: 1, sm: 2 }}>
                      {isMobile && (
                        <>
                          <Typography
                            variant="caption"
                            sx={{
                              position: 'relative',
                              top: '2px',
                              cursor: 'pointer',
                              color: theme.palette.common.white,
                              letterSpacing: '0.02em',

                              fontWeight: 400,
                            }}
                            paddingRight={'6px'}
                            onClick={() => redirectToLogin(navigate, location)}
                          >
                            Log In
                          </Typography>
                        </>
                      )}

                      {!isMobile && (
                        <Button
                          color="inherit"
                          size={isMobile ? 'small' : 'medium'}
                          sx={{
                            borderRadius: '32px',
                            minWidth: '20px',
                            p: { xs: '14px 12px', sm: '8px 12px', lg: '8px 16px' },
                            color: theme.palette.common.white,
                            letterSpacing: '0.02em',

                            fontWeight: 400,
                          }}
                          variant="text"
                          onClick={() => redirectToLogin(navigate, location)}
                        >
                          Log In
                        </Button>
                      )}
                      <Button
                        sx={{
                          background: theme.palette.primary.main,
                          borderRadius: '32px',
                          p: { xs: '14px 12px', sm: '8px 12px', lg: '8px 16px' },
                          letterSpacing: '0.02em',

                          fontWeight: 400,
                        }}
                        color="primary"
                        size={isMobile ? 'small' : 'medium'}
                        variant="contained"
                        onClick={handleButtonClick('/signup')}
                      >
                        Sign Up
                      </Button>
                    </Stack>
                  )}
                  <UserAppDrawer />
                </Stack>
              </Toolbar>
            </Container>
          </MainAppBar>
        </HideOnScroll>
        {isMobile && (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem disablePadding onClick={handleButtonClick('/leads')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="explore leads"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Leads"
                    primaryTypographyProps={{
                      color: 'grey.800',
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/newbrief')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="post brief"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Post Brief"
                    primaryTypographyProps={{
                      color: 'grey.800',
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/leads/my-proposals')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="my proposals"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="My Proposals"
                    primaryTypographyProps={{
                      color: 'grey.800',
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/talent')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="explore talent"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Talent"
                    primaryTypographyProps={{
                      color: 'grey.800',
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding onClick={handleButtonClick(authContext?.user ? '/subscriptions' : '/pricing')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="pricing"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Pricing & FAQs"
                    primaryTypographyProps={{
                      color: 'grey.800',
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/about')}>
                <ListItemButton>
                  <ListItemText aria-label="about" aria-controls="menu-appbar" aria-haspopup="true" primary="About" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/contactus')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="contact"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Contact"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={() => (window.location.href = helpUrl)}>
                <ListItemButton>
                  <ListItemText aria-label="help" aria-controls="menu-appbar" aria-haspopup="true" primary="Help" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/privacy')}>
                <ListItemButton>
                  <ListItemText
                    aria-label="privacy"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Privacy"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={handleButtonClick('/terms')}>
                <ListItemButton>
                  <ListItemText aria-label="terms" aria-controls="menu-appbar" aria-haspopup="true" primary="T&Cs" />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        )}
      </Box>
    </>
  );
}
