import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import MyOpportunityAccordion from 'components/opportunities/myApplications/myApplicationAccordion';
import MyOppFilters from 'components/opportunities/myApplications/myOppFilters';
import { IOpportunity } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { getMyApplications } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';

export default function MyApplications() {
  useTitle('My Proposals');
  const [loading, setLoading] = useState(true);
  const [opps, setOpps] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);

  useEffect(() => {
    getMyApplications()
      .then(res => {
        setOpps(res);
        setFilteredOpps(res);
      })
      .catch(showError)
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container direction={'row-reverse'} mt={4} spacing={2}>
        <Grid container spacing={4} sx={{ pl: { xs: 2, md: 0 } }}>
          <Grid item xs={12} md={3}>
            <MyOppFilters opps={opps} setFilteredOpps={setFilteredOpps} setLoading={setLoading} />
          </Grid>
          {loading && (
            <Grid item xs={12} sx={{ mb: 5 }} md={9}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="19vh" mt={{ xs: 0, md: 5 }}>
                <CircularProgress />
              </Box>
            </Grid>
          )}
          {!loading && (
            <Grid item xs={12} md={9} sx={{ mb: 6 }}>
              <Box mt={{ xs: 0, md: 5 }}>
                <Typography variant="subtitle1" mb={2.25}>
                  {filteredOpps.length} {filteredOpps.length == 1 ? 'proposal' : 'proposals'} in the last 30 days
                </Typography>
                {filteredOpps.map((opportunity: IOpportunity, index: number) => (
                  <MyOpportunityAccordion key={index} opportunity={opportunity}></MyOpportunityAccordion>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
