import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useTitle } from 'utils/router';

import OpportunitySubscribe from 'components/opportunities/newsletter/OpportunitySubscribe';
import ViewOpportunitiesComponent from 'components/opportunities/ViewOpportunitiesComponent';
import HeroSection, { IReview, IUsp } from 'components/info/Hero';
import { ClockIcon } from 'components/icon/ClockIcon';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';

const ViewOpportunities = () => {
  useTitle('New Leads');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const review: IReview = {
    name: 'Imogen H',
    roleTitle: 'Marketing Manager',
    reviewText: '"I’ve landed two amazing long term clients!"',
  };

  const usps: IUsp[] = [
    {
      icon: (
        <BriefIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: '1,000+ Leads Every Month',
      text: 'Get instant access to high-quality freelance leads every month, sourced from 30+ platforms.',
    },
    {
      icon: (
        <ClockIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Always Fresh, Never Stale',
      text: 'Leads are updated throughout the day, so you can pitch early and win more work.',
    },
    {
      icon: (
        <ExclusiveIcon
          sx={{
            width: !isMobile ? '24px' : '20px',
            height: !isMobile ? '24px' : '20px',
          }}
          htmlColor={theme.palette.common.black}
        />
      ),
      title: 'Save Time, Earn More',
      text: 'Stop wasting hours searching—Shoutt filters out the junk, so you only see quality leads.',
    },
  ];
  return (
    <>
      <Box sx={{ backgroundColor: '#F8F7F4', minHeight: '1000px' }}>
        <HeroSection
          title={
            isMobile
              ? 'High quality freelance leads straight to you'
              : 'High quality freelance leads, delivered straight to you'
          }
          review={review}
          usps={usps}
        />
        <OpportunitySubscribe />
        <Container maxWidth="lg">
          <ViewOpportunitiesComponent />
        </Container>
      </Box>
    </>
  );
};

export default ViewOpportunities;
