import { Box, Chip, Stack, useTheme } from '@mui/material';
import { IStorageFile } from 'global/interfaces/file';
import { triggerFileDownload } from 'utils/file';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export function ApplicantAttachments({ files }: { files: IStorageFile[] }) {
  const theme = useTheme();
  if (!files.length || files.length === 0) {
    return <></>;
  }

  return (
    <Box>
      <Stack direction="row" gap={2} flexWrap="wrap">
        {files.map((f, i) => (
          <Chip
            key={i}
            onClick={() =>
              triggerFileDownload(`${process.env.REACT_APP_CDN_URL}${f.url}`, `${f.name}${f.extension}`, true, f.type)
            }
            label={`${f.name}${f.extension}`}
            variant="outlined"
            icon={<InsertDriveFileOutlinedIcon sx={{ width: '16px', height: '16px' }} />}
            sx={{
              '& .MuiChip-icon': {
                marginLeft: '8px',
                marginRight: '-8px',
              },
              backgroundColor: theme.palette.grey[200],
              borderColor: theme.palette.grey[200],
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}
