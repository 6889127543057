import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { AuthContext } from 'contexts/AuthContext';
import { format } from 'date-fns';
import { Dispatch, useContext } from 'react';
import { cancelSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { Close } from '@mui/icons-material';
import { DowngradeReason, IDowngradeSubscription, UserSubscription } from 'global/interfaces/userSubscription';
import { SelectItem } from 'global/interfaces/selects';
import { useFormik } from 'formik';
import { downgradeSubscriptionValidation } from 'global/validations/downgradeSubscription';
import FormSelect from 'components/common/Select/FormSelect';

interface IInactiveDiscountDetailsProps {
  loading: boolean;
  setLoading: Dispatch<boolean>;
  subscription: UserSubscription | undefined;
  loadSubscription: () => void;
  handleClose: () => void;
}

export default function InactiveDiscountDetails({
  setLoading,
  loadSubscription,
  handleClose,
  subscription,
  loading,
}: IInactiveDiscountDetailsProps) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const form = useFormik<IDowngradeSubscription>({
    initialValues: {
      reason: null,
      comment: undefined,
    },
    validationSchema: downgradeSubscriptionValidation,
    onSubmit: values => {
      setLoading(true);
      cancelSubscription(values)
        .then(() => {
          authContext.refresh().then(() => {
            loadSubscription();
            handleClose();
            setLoading(false);
          });
        })
        .catch(e => {
          showError(e);
          setLoading(false);
        });
    },
  });

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Downgrade to Free Plan
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        Why do you want to downgrade?
        <Box>
          <FormSelect
            label="Reason"
            name="reason"
            items={ReasonSelectList}
            onChange={form.handleChange}
            error={form.touched.reason && Boolean(form.errors.reason)}
            helpertext={form.touched.reason ? form.errors.reason : ''}
          />
        </Box>
        {form.values.reason === DowngradeReason.other && (
          <Box>
            <TextField
              fullWidth
              name="comment"
              value={form.values.comment}
              onChange={form.handleChange}
              variant={'outlined'}
              multiline
              rows={6}
              error={form.touched.comment && Boolean(form.errors.comment)}
              helperText={form.touched.comment ? form.errors.comment : ''}
            />
          </Box>
        )}
        {subscription?.discountApplied && <Box>As a reminder you have 50% off next month.</Box>}
        By downgrading your premium plan will end on{' '}
        {subscription?.subscriptionCurrentPeriodEnd &&
          format(new Date(subscription.subscriptionCurrentPeriodEnd), 'MMMM d, yyyy')}
        . You can still use premium features until that date. Would you like to downgrade now?
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={handleClose} disabled={loading}>
          Close
        </RoundButton>
        <RoundButton
          variant="contained"
          fullWidth={isMobileScreen}
          onClick={() => form.handleSubmit()}
          type="submit"
          loading={loading}
        >
          Continue
        </RoundButton>
      </StyledDialogActions>
    </>
  );
}

const ReasonSelectList: SelectItem[] = [
  {
    id: DowngradeReason.low_quality,
    label: 'Not enough opportunities for my skillset',
  },
  {
    id: DowngradeReason.unused,
    label: "I have enough work now so don't need this",
  },
  {
    id: DowngradeReason.switched_service,
    label: 'I can find the external opportunities myself',
  },
  {
    id: DowngradeReason.too_expensive,
    label: 'Too expensive',
  },
  {
    id: DowngradeReason.customer_service,
    label: 'Customer service has been poor',
  },
  {
    id: DowngradeReason.other,
    label: 'Other',
  },
];
